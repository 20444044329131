import React from 'react';
import signUpBannerImg from '../Asssets/Images/signUpBannerImg.png';
import { ReactComponent as CheckMrk } from '../Asssets/icons/checkMrk.svg';

import css from './SignupForm.module.css';

function SignUpBanner() {
  return (
    <>
      <div className={css.innerLft}>
        <div className={css.headingSection}>
          Begin An <br /> Extraordinary Journey
        </div>
        <div className={css.pointContainer}>
          <div className={css.iconSection}>
            <CheckMrk />
          </div>
          <div className={css.labelContainer}>
            Connect with extraordinary animal talent and businesses worldwide
          </div>
        </div>
        <div className={css.pointContainer}>
          <div className={css.iconSection}>
            <CheckMrk />
          </div>
          <div className={css.labelContainer}>
            Experience the convenience of managing everything in one place
          </div>
        </div>
        <div className={css.pointContainer}>
          <div className={css.iconSection}>
            <CheckMrk />
          </div>
          <div className={css.labelContainer}>Enjoy seamless communication</div>
        </div>
        <div className={css.pointContainer}>
          <div className={css.iconSection}>
            <CheckMrk />
          </div>
          <div className={css.labelContainer}>Safe and Secure</div>
        </div>
      </div>
      <div className={css.innerRight}>
        <img src={signUpBannerImg} className={css.signUpBannerImg} />
      </div>
    </>
  );
}

export default SignUpBanner;
