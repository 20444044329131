import React from 'react';
import css from './LoginForm.module.css';

import LoginForm from './LoginForm';
import SignUpBanner from '../SignupForm/SignUpBanner';
import { parse } from '../../../util/urlHelpers';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import LoginOptionsPage from '../LoginForm/LoginOptionPage';
import { FormattedMessage } from 'react-intl';

const EMAIL_FLOW = 'email';

function LoginRootPageComponent(props) {
  const { location, history } = props;
  const { flw: loginFlow } = parse(location.search);
  return (
    <div className={css.mainContainer}>
      <div className={css.leftContainer}>
        <SignUpBanner />
      </div>
      <div className={css.rightContainer}>
        {loginFlow && loginFlow === EMAIL_FLOW ? (
          <LoginForm {...props} />
        ) : (
          <LoginOptionsPage history={history} {...props} />
        )}

        <div className={css.termsSection}>
          <FormattedMessage id="Login.TNC" />
        </div>
      </div>
    </div>
  );
}

const LoginRootPage = compose(withRouter)(LoginRootPageComponent);

export default LoginRootPage;
