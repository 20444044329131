import React from 'react';
import css from './LoginForm.module.css';

import { ReactComponent as Google } from '../Asssets/icons/google.svg';
import { ReactComponent as Fb } from '../Asssets/icons/fb.svg';
import { ReactComponent as LinkedIn } from '../Asssets/icons/linkedIn.svg';
import { ReactComponent as Email } from '../Asssets/icons/email.svg';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

function LoginOptionPage(props) {
  const { history, authWithGoogle, authWithFacebook, authWithLinkedIn } = props;
  const routes = useRouteConfiguration();
  return (
    <>
      <div className={css.signupHeader}>Sign in to your account</div>
      <div className={css.loginlink}>
        Don't have an account?{' '}
        <span
          onClick={() => history.push(createResourceLocatorString('SignupPage', routes, {}, {}))}
        >
          Sign up?
        </span>
      </div>
      <div className={css.signUpModuleContainer}>
        <div className={css.signUpModule} onClick={() => authWithGoogle()}>
          <div className={css.moduleIcon}>
            <Google />
          </div>
          <div className={css.moduleLabel}>Continue with Google</div>
        </div>

        {/* <div className={css.signUpModule} onClick={() => authWithFacebook()}>
          <div className={css.moduleIcon}>
            <Fb />
          </div>
          <div className={css.moduleLabel}>Continue with Facebook</div>
        </div> */}

        <div className={css.signUpModule} onClick={() => authWithLinkedIn()}>
          <div className={css.moduleIcon}>
            <LinkedIn />
          </div>
          <div className={css.moduleLabel}>Continue with LinkedIn</div>
        </div>
        <div className={css.moduleOr}>OR</div>
        <div
          className={css.signUpModule}
          onClick={() =>
            history.push(createResourceLocatorString('LoginPage', routes, {}, { flw: 'email' }))
          }
        >
          <div className={css.moduleIcon}>
            <Email />
          </div>
          <div className={css.moduleLabel}>Continue with Email</div>
        </div>
      </div>
    </>
  );
}

export default LoginOptionPage;
